import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/header";
import Container from "react-bootstrap/Container";
import Footer from "../components/Footer";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import aboutimg from "../assets/Placeholder image.png";
import tickblue from "../assets/Group 3.png";
import sms1 from "../assets/sms1.png";
import email3 from "../assets/email (2).png";
import mobsms2 from "../assets/mosms.png";
import sms from "../assets/sms.png";
import numberverfy from "../assets/pop1.png";
import axiosClient from "../Services/axiosClient";
import { BASE_URL, ABOUT_US } from "../API";
import { toast } from "react-toastify";

function AboutUs() {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    try {
      const response = await axiosClient.get(BASE_URL + ABOUT_US);
      if (response?.data?.code === 200) {
        setData(response.data.data);

        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error(error, "errorr");
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message || "An error occurred";
        toast.error(errorMessage);
      } else {
        toast.error("An error occurred");
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Header />
      {/* about us -------------------------------------- */}
      <section className="about-us-sec comn-padd-sec">
        <Container>
          <Row className="gap-main-clas">
            <Col lg={6}>
              <div className="about-us-img">
                <figure>
                  <img src={aboutimg} alt="" className="img-fluid" />
                </figure>
              </div>
            </Col>
            <Col lg={6}>
              <div className="text-about-sec">
                <h2>{data[1]?.keyValue}</h2>
                <p dangerouslySetInnerHTML={{__html : data[2]?.keyValue}}/>
                <button className="get-start-btn-comn">Learn More</button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* number verify ---------------------------------------- */}
      <section className="number-verify-sec comn-padd-sec pt-0">
        <Container>
          <Row className="gap-main-clas">
            <Col lg={6} className="order-name1">
              <div className="text-about-sec">
                <h2>{data[5]?.keyValue}</h2>
                <p dangerouslySetInnerHTML={{__html : data[6]?.keyValue}}/>
              </div>

              <div className="custm-send-steps">
                <div className="first-step-send d-flex">
                  <div className="sms-logo-main-upper">
                    <div className="inner-back-sms-ogo">
                      <img src={mobsms2} alt="" />
                    </div>
                  </div>
                  <div className="content-tetx-step-sms">
                    <h5>{data[16]?.keyValue}</h5>
                    <p dangerouslySetInnerHTML={{__html : data[9]?.keyValue}}/>
                  </div>
                </div>
                <div className="first-step-send d-flex">
                  <div className="sms-logo-main-upper">
                    <div className="inner-back-sms-ogo">
                      <img src={email3} alt="" />
                    </div>
                  </div>
                  <div className="content-tetx-step-sms">
                  <h5>{data[17]?.keyValue}</h5>
                    <p dangerouslySetInnerHTML={{__html : data[10]?.keyValue}}/>
                  </div>
                </div>
                <div className="first-step-send d-flex">
                  <div className="sms-logo-main-upper">
                    <div className="inner-back-sms-ogo">
                      <img src={sms1} alt="" />
                    </div>
                  </div>
                  <div className="content-tetx-step-sms">
                  <h5>{data[18]?.keyValue}</h5>
                    <p dangerouslySetInnerHTML={{__html : data[11]?.keyValue}}/>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} className="order-name2">
              <div className="img-with-goo-upper">
                <figure>
                  <img src={numberverfy} className="img-fluid" alt="" />
                </figure>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* sms verify ----------------------------- */}
      <section className="sms-verify-sec comn-padd-sec pt-0">
        <Container>
          <Row className="gap-main-clas">
            <Col lg={5}>
              <div className="about-us-img">
                <figure>
                  <img src={sms} alt="" className="img-fluid" />
                </figure>
              </div>
            </Col>
            <Col lg={7}>
              <div className="text-about-sec">
                <h2>{data[12]?.keyValue}</h2>
                <p dangerouslySetInnerHTML={{__html : data[13]?.keyValue}}/>

                <ul className="list-banner">
                  <li>
                    <img src={tickblue} alt="" />
                    <p dangerouslySetInnerHTML={{__html : data[19]?.keyValue}}/>

                  </li>
                  <li>
                    <img src={tickblue} alt="" />
                    <p dangerouslySetInnerHTML={{__html : data[20]?.keyValue}}/>

                  </li>
                  <li>
                    <img src={tickblue} alt="" />
                    <p dangerouslySetInnerHTML={{__html : data[21]?.keyValue}}/>
                  </li>
                  <li>
                    <img src={tickblue} alt="" />
                    <p dangerouslySetInnerHTML={{__html : data[22]?.keyValue}}/>
                  </li>
                  <li>
                    <img src={tickblue} alt="" />
                    <p dangerouslySetInnerHTML={{__html : data[23]?.keyValue}}/>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </>
  );
}

export default AboutUs;
