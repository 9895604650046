import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/Footer";
import green from "../Assets/Images/green.png";
import { Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import game1 from "../Assets/Images/game1.png";
import ludo from "../Assets/Images/ludo.png";
import pubg from "../Assets/Images/pubg.png";
import speed from "../Assets/Images/speed.png";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL, GAME_LIST, IMAGE_BASE_URL } from "../API";
import Pagination from "react-bootstrap/Pagination";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import logo from "../Assets/Images/eu.png";
const ITEMS_PER_PAGE=8;

function Games() {
  const navigate = useNavigate();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));

  const [activeMenu, setActiveMenu] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [gameList, setGameList] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    getGameList();
  }, [currentPage]);

  const getGameList = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.get(BASE_URL + GAME_LIST, {
        headers: headers,
      });

      if (response.data.status_code === 200 && response.data.status === true) {

        const getGames = response.data.data;
        const totalCount = getGames.length;
        setTotalPages(Math.ceil(totalCount / ITEMS_PER_PAGE));
        const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
        const currentItems = getGames.slice(startIndex,startIndex + ITEMS_PER_PAGE);
        setGameList(currentItems);
        setIsLoading(false);

        //   toast.success(response.data.message);
      } else if (
        response.data.status_code === 400 &&
        response.data.status === false
      ) {
        toast.error(response?.data?.error[0]);
        setIsLoading(false);
      } else if (
        response.data.status_code === 403 &&
        response.data.status === false
      ) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        // console.log('response.data::',response)
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message || "An error occurred";
        setIsLoading(false);
        toast.error(errorMessage);
      } else {
        toast.error("An error occurred");
        setIsLoading(false);
      }
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <Header />
      <section className="back-profile">
        <Container>
          <div className="inner-banner-prof">
            <img src={green} alt="" />
            {/* <p className="subtitle-hero"># World Class ESports & Gaming Site</p> */}
            <h1>
              <span className="span-heromain">ESports Games</span>
            </h1>
          </div>
        </Container>
      </section>

      <section className="comn-padd">
        <Container>
          <div className="innercrads-game">
            <Row>
              {gameList &&
                gameList.length ?
                gameList.map((element) => {
                  console.log("element::", element);
                  return (
                    <Col key={element.id} lg={3} md={4} xs={6} onClick={()=> navigate("/tournament?Game=" +  element.id,{state:{game_id:element.id}})}>
                      <div className="img-card-games">
                        <figure>
                          <img
                            src={
                              element.game_banner
                                ? IMAGE_BASE_URL + element.game_banner
                                : logo
                            }
                            alt=""
                          />
                        </figure>
                        <h6>{element.game_name}</h6>
                      </div>
                    </Col>
                  );
                }):<p className="text-main-all">Nothing to show , Right Now !!! </p> }
            </Row>
          </div>
        </Container>
        {totalPages>0?
              <Pagination>
                <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage==1}/>
                <Pagination.Prev onClick={() => handlePageChange(Math.max(currentPage - 1, 1))} disabled={currentPage==1}/>
                {Array.from({ length: totalPages }).map((_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))} disabled={currentPage==totalPages}/>
                <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage==totalPages}/>
              </Pagination>:<></>}
      </section>

      <section className="profesniol comn-padd how-it-work">
        <Container>
          <div className="inner-rpofesional">
            <h1 className="span-heromain">
            <span >Eu Pro Scene</span> <br /> 
              {/* <span>Professional EuEsport Gaming</span> <br />{" "}
              <span className="tournament-main">TOURNAMENT</span> */}
            </h1>

            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              auctor massa ut metus dictum facilisis. Nunc eget erat ac nunc
              condimentum tempus vitae et dui. Vestibulum sem lorem, cursus id
              interdum et, cursus in lorem. Aliquam hendrerit lacinia mauris, a
              interdum orci aliquet nec.
            </p> */}
            {
                !user ?
                <div className="header-button d-flex">
                <a className="login-head" onClick={() => navigate('/login')}>
                  Login
                </a>
                <div className="d-xxl-block">
                  <a className="th-btn th_btn"  onClick={() => navigate('/signup')}>
                    REGISTER NOW
                  </a>
                </div>
              </div>
              :
              <></>
            }
          </div>
        </Container>
      </section>

      <Footer />
    </>
  );
}

export default Games;
