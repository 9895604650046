import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/Footer";
import green from "../Assets/Images/green.png";
import img1 from "../Assets/Images/card1.png";
import img2 from "../Assets/Images/card2 .png";
import img3 from "../Assets/Images/card3 (2).png";
import img4 from "../Assets/Images/card4.png";
import img5 from "../Assets/Images/crad5.png";
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { Button, Container } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL, TOURNAMENT_LIST, IMAGE_BASE_URL } from "../API";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "../components/Loader";
import logo from "../Assets/Images/eu.png";
import Offcanvas from 'react-bootstrap/Offcanvas';
import moment from "moment";
import Pagination from 'react-bootstrap/Pagination';
import { useForm, Controller } from "react-hook-form";

const ITEMS_PER_PAGE = 3;

function Tournament() {
  const navigate = useNavigate();
  const location = useLocation()
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")))

  const [activeMenu, setActiveMenu] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [tournamentList, setTournamentList] = useState([]);
  const [pageContent, setPageContent] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tournamentState, setTournamentState] = useState('all');
  const [selectedGameId, setSelectedGameId] = useState(location.state?.game_id ? location.state.game_id : '');
  const [show, setShow] = useState(false);
  const [startValue, setStartValue] = useState(0);
  const [totalValue, setTotalValue] = useState(100); // Maximum value of the range input
  const [filterOptions, setFilterOptions] = useState();
  const [selectedEntryFee, SetSelectedEntryFee] = useState(0);
  const [selectedTotalTeam, SetSelectedTotalTeam] = useState(0);
  const [selectedWinningPrice, SetSelectedWinningPrice] = useState(0);
  const [selectedResion, setSelectedResion] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  // const [currentSearchPage, setCurrentSearchPage] = useState(1);
  // const [totalSearchPages, setTotalSearchPages] = useState(1);
  const [filters, setFilters] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [flag,setFlag]=useState(true)



  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    register
  } = useForm();

  useEffect(() => {
      if(flag){
        getTournamentList();
      }
      else{
        handleSearchChange(searchTerm);
      }
    }, [currentPage, tournamentState, selectedGameId,filters]);
    
    
    const handleSearchChange = (searchContent) => {
      setSearchTerm(searchContent);
      if (searchContent.length!=0) { 
        setFlag(false)
        const filteredTournaments = tournamentList.filter(tournament =>
          tournament.tournament_name.toLowerCase().includes(searchContent.toLowerCase())
          );
          const totalCount = filteredTournaments.length;
          setTotalPages(Math.ceil(totalCount / ITEMS_PER_PAGE));
          const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
          const currentItems = filteredTournaments.slice(startIndex, startIndex + ITEMS_PER_PAGE);
          setPageContent(currentItems);
          console.log(filteredTournaments);
        }
        else{
      setFlag(true)
      getTournamentList();
    }
    console.log('Search term:', searchContent);
  };
  

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const getTournamentList = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      
      const url = filters
  ? `${BASE_URL}${TOURNAMENT_LIST}?tournament_state=${tournamentState}&game_id=${selectedGameId}&total_teams=${filters.max_total_teams}&entry_fee=${filters.max_entry_fee}&winning_prize=${filters.max_winning_prize}&region=${filters.region}`
  : `${BASE_URL}${TOURNAMENT_LIST}?tournament_state=${tournamentState}&game_id=${selectedGameId}`;
      const response = await axios.get(url, {
        headers: headers,
      });

      if (response.data.status_code === 200 && response.data.status === true) {
        setIsLoading(false);
        console.log(response.data.tournaments)
        setFilterOptions(response.data.data)
        const tournaments = response.data.tournaments;
        setTournamentList(tournaments)
        const totalCount = tournaments.length;
        setTotalPages(Math.ceil(totalCount / ITEMS_PER_PAGE));
        const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
        const currentItems = tournaments.slice(startIndex, startIndex + ITEMS_PER_PAGE);
        setPageContent(currentItems);

        //   toast.success(response.data.message);
      } else if (
        response.data.status_code === 400 &&
        response.data.status === false
      ) {
        toast.error(response?.data?.error[0]);
        setIsLoading(false);
      } else if (
        response.data.status_code === 403 &&
        response.data.status === false
      ) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        // console.log('response.data::',response)
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message || "An error occurred";
        setIsLoading(false);
        toast.error(errorMessage);
      } else {
        toast.error("An error occurred");
        setIsLoading(false);
      }
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  const MoveOnViewDetails = (element) => {
    // console.log(element)
    navigate("/viewtournament", { state: { element } });
  };


  const onSubmit = async (data) => {
    setFlag(true)
    setFilters(data)
    handlePageChange(1)
    handleClose();
    console.log(filters)
    console.log(pageContent)
  }
  
  const handleReset=()=>{
    setFilters();
    setCurrentPage(1);
    handleClose();
    reset();
    
  }



  return (


    <>
      <Loader isLoading={isLoading} />

      <Header />
      <section className="back-profile">
        <Container>
          <div className="inner-banner-prof">
            <img src={green} alt="" />
            {/* <p className="subtitle-hero">#£200 ELITE 3v3 SND BEST OF World Class ESports & Gaming Site</p> */}
            <h1>
              <span className="span-heromain">Tournaments</span>
            </h1>
          </div>
        </Container>
      </section>

      <section>
        <Container>
          <div className="inner-tournament-amin comn-padd">
            <h2>Tournaments</h2>
            <div className="btns-search-filter d-flex justify-content-between ">
              <div className="upper-flex-btns d-flex">
                <div className="d-xxl-block " onClick={() => {setTournamentState('all');handlePageChange(1)}}>
                  <p className="th-btn th_btn mb-0" >
                    All
                  </p>
                </div>
                <div className="d-xxl-block m-0" onClick={() => {setTournamentState('active');handlePageChange(1)}}>
                  <p className="th-btn th_btn mb-0" >
                    Active
                  </p>
                </div>
                <div className="d-xxl-block mb-0" onClick={() => {setTournamentState('upcoming');handlePageChange(1)}}>
                  <p className="th-btn th_btn mb-0">
                    Upcoming
                  </p>
                </div>
              </div>

              <div className="upper-filter-main">
                <Form.Group controlId="formGroupEmail">
                  <i className="fa-solid fa-magnifying-glass"></i>
                  <Form.Control type="text" className="search-filter" placeholder="Search" value={searchTerm} onChange={(e)=>{handleSearchChange(e.target.value);handlePageChange(1)}}/>
                </Form.Group>

                <div className="upper-filter-main">

                  <div className="d-xxl-block mb-0" onClick={()=>handleShow()}><p className="th-btn th_btn mb-0">Filter</p></div>
                  <Offcanvas show={show} onHide={()=>handleClose()} placement="end">
                    <Offcanvas.Header closeButton>
                      <Offcanvas.Title>Filter</Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <Form onSubmit={handleSubmit(onSubmit)}>
                        <div className="inner-body-ofcanvas">
                          <div className="upper-range">
                            <Form.Label>Entry Fees</Form.Label>
                            <Form.Control
                              type="range"
                              className="form-range custom-range"
                              min="0"
                              max={filterOptions?.max_entry_fee} // Set the maximum value dynamically
                              step="1"
                              id="max_entry_fee"
                              {...register('max_entry_fee')}
                              onChange={(event) => SetSelectedEntryFee(event.target.value)}
                            />
                            <div className="upper-range-main d-flex">
                              <p>{selectedEntryFee}</p>
                              <p>{filterOptions?.max_entry_fee}</p>
                            </div>
                          </div>
                          <div className="upper-range">
                            <Form.Label>Total Teams</Form.Label>
                            {/* <Form.Range className="custom-range" /> */}
                            <Form.Control
                              type="range"
                              className="form-range custom-range"
                              min="0"
                              max={filterOptions?.max_total_teams} // Set the maximum value dynamically
                              step="1"
                              id="max_total_teams"
                              {...register('max_total_teams')}
                              onChange={(event) => SetSelectedTotalTeam(event.target.value)}
                            />
                            <div className="upper-range-main d-flex">
                              <p>{selectedTotalTeam}</p>
                              <p>{filterOptions?.max_total_teams}</p>
                            </div>
                          </div>
                          <div className="upper-range">
                            <Form.Label>Winning Price</Form.Label>
                            {/* <Form.Range className="custom-range" /> */}
                            <Form.Control
                              type="range"
                              className="form-range custom-range"
                              min="0"
                              max={filterOptions?.max_winning_prize} // Set the maximum value dynamically
                              step="1"
                              id="max_winning_prize"
                              {...register('max_winning_prize')}
                              onChange={(event) => SetSelectedWinningPrice(event.target.value)}
                            />
                            <div className="upper-range-main d-flex">
                              <p>{selectedWinningPrice}</p>
                              <p>{filterOptions?.max_winning_prize}</p>
                            </div>
                          </div>
                          {/* <Form.Group className="mb-3 comn-inpt-main" controlId="formGroupEmail">
                            <Form.Label>Select Date</Form.Label>
                            <Form.Control type="date" placeholder="Enter email" />
                          </Form.Group> */}

                          <Form.Group controlId="formGridState" className="comn-inpt-main">
                            <Form.Label>Regions</Form.Label>
                            <Form.Select defaultValue="Choose..." {...register('region')} onChange={(event) => setSelectedResion(event.target.value)}>
                              <option>Choose...</option>
                              {
                                filterOptions?.regions.length &&
                                filterOptions.regions.map((element,key) => {
                                  return (
                                    <option key={key} value={element}>{element}</option>
                                  )
                                })
                              }
                            </Form.Select>
                          </Form.Group>
                        <div className="upper-sidebr-btns d-flex justify-content-between">
                          <div className="upper-btn-login-page text-end mt-4">
                            <button className="edit-butt" type="submit">Apply</button>
                          </div>
                          <div className="upper-btn-login-page text-end mt-4">
                            <button className="edit-butt" type="reset" onClick={()=>handleReset()}>Reset</button>
                          </div>
                        </div>
                        </div>
                      </Form>
                    </Offcanvas.Body>
                  </Offcanvas>
                </div>
              </div>


            </div>
            <div className="cards-tournaments">
              <div className="row">
                {pageContent &&
                  pageContent.length ?
                  (pageContent.map((element) => {
                    return (
                      <div key={element.id} className="col-lg-4 col-md-6">
                        <div className="inner-card-one">
                          <figure className="card-img">
                            <img
                              src={
                                element.tournament_banner
                                  ? IMAGE_BASE_URL + element.tournament_banner
                                  : logo
                              }
                              alt=""
                            />
                          </figure>
                          <div className="upper-mian-text-card">
                            <h4>{element.tournament_name}</h4>
                            <p>{element.description} </p>
                          </div>
                          <div className="entry-fees-all d-flex">
                            <div className="first-entry-fee">
                              <p>
                                Entry Fee:{" "}
                                <span>{element.entry_fee} Credits</span>
                              </p>
                            </div>
                            <div className="first-entry-fee">
                              <p>
                                Max Teams:{" "}
                                <span>{element.total_teams} Teams</span>
                              </p>
                            </div>
                          </div>
                          <div className="region-date d-flex">
                            <div className="region-card">
                              <p>
                                Region: <span>{element.region}</span>
                              </p>
                            </div>
                            <div className="region-card">
                              <p>
                                Team Size: <span>{element.team_size}V{element.team_size}</span>
                              </p>
                            </div>
                          </div>
                          <div className="region-date d-flex">
                            <div className="region-card">
                              <p>
                                Start Date:{" "}
                                <span>{moment(element.tournament_start_date).format('DD-MM-YYYY HH:mm')}</span>
                              </p>
                            </div>
                            <div className="region-card">
                              <p>
                                Reg End:{" "}
                                <span>{moment(element.registration_deadline_date).format('DD-MM-YYYY HH:mm')}</span>
                              </p>
                            </div>
                          </div>
                          <div className="region-date d-flex">
                            {/* <div className="region-card">
                              <p>
                                Reg End:{" "}
                                <span>{moment(element.registration_deadline_date).format('DD-MM-YYYY HH:mm')}</span>
                              </p>
                            </div> */}
                          </div>
                          <div
                            className="d-xxl-block"
                            onClick={() => MoveOnViewDetails(element)}
                          >
                            <a className="th-btn th_btn">
                              VIEW DETAILS
                              <i className="fa-solid fa-arrow-right ms-2"></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    );
                  })):<p>Nothing to show , Right Now !!! </p> 
                  }
              </div>


              {totalPages>0?
              <Pagination>
                <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage==1}/>
                <Pagination.Prev onClick={() => handlePageChange(Math.max(currentPage - 1, 1))} disabled={currentPage==1}/>
                {Array.from({ length: totalPages }).map((_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))} disabled={currentPage==totalPages}/>
                <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage==totalPages}/>
              </Pagination>:<></>}




            </div>
          </div>
        </Container>
      </section>

      <section className="profesniol comn-padd how-it-work">
        <Container>
          <div className="inner-rpofesional">
            <h1 className="span-heromain">
              <span >Eu Pro Scene</span> <br /> 
              {/* <span>Professional EuEsport Gaming</span> <br />{" "}
              <span className="tournament-main">TOURNAMENT</span> */}
            </h1>

            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
              auctor massa ut metus dictum facilisis. Nunc eget erat ac nunc
              condimentum tempus vitae et dui. Vestibulum sem lorem, cursus id
              interdum et, cursus in lorem. Aliquam hendrerit lacinia mauris, a
              interdum orci aliquet nec.
            </p> */}
            {
              !user ?
                <div className="header-button d-flex">
                  <a className="login-head" onClick={() => navigate('/login')} >
                    Login
                  </a>
                  <div className="d-xxl-block">
                    <a className="th-btn th_btn" onClick={() => navigate('/signup')}>
                      REGISTER NOW
                    </a>
                  </div>
                </div>
                :
                <></>
            }

          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default Tournament;
