import axios from "axios";
import { BASE_URL } from "../API";

const axiosClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  }
});
const axiosClientWithToken = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    'Authorization': 'Bearer ' + localStorage.getItem('token'),
  }
});


export default axiosClient;