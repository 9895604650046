import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios'
import { BASE_URL,PLAYER_LIST } from "../API";
import { toast } from "react-toastify";
import moment from "moment";

function OtherTeamsView({ selectedTeam }) {
    const [playerLobbyList,setPlayerLobbyList]=useState([]);
    const [captainDetails,setCaptainDetails]=useState({});

    useEffect(() => {
        getPlayerLobby();
        setCaptainDetails(selectedTeam.captain)
    }, []);

    const getPlayerLobby=async()=>{
        const token=localStorage.getItem('token')
        try{
            const headers = {Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            };
            const response = await axios.get(BASE_URL + PLAYER_LIST + '?team_id=' + selectedTeam.id, {
                headers: headers,
            });
            console.log(response.data.players)
              if (response.data.status_code === 200 && response.data.status === true) {
                setPlayerLobbyList(response.data.players)
                //   toast.success(response.data.message);
              } else if (
                response.data.status_code === 400 &&
                response.data.status === false
              ) {
                toast.error(response?.data?.error[0]);
              } else if (
                response.data.status_code === 403 &&
                response.data.status === false
              ) {
                localStorage.removeItem("user");
                localStorage.removeItem("token");
              } else {
                // console.log('response.data::',response)
                toast.error(response.data.message);
              }
            } catch (error) {
              if (error.response && error.response.data) {
                const errorMessage = error.response.data.message || "An error occurred";
                toast.error(errorMessage);
              } else {
                toast.error("An error occurred");;
              }
            }
    }

    return (
        <>
            <section>
                <div className="edit-profile-main">
                    <div className="upper-form-main">
                        <div className="form-main-name-detal team-detail-main">
                            <div className="team-name-all">
                                <div className="team-name">
                                    <h6>Team Name</h6>
                                    <p>{selectedTeam.team_name}</p>
                                </div>

                                <div className="team-name">
                                    <h6>Total Players</h6>
                                    <p>{selectedTeam.total_players} Players</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section className='view-table-main'>
                <div className="upper-btn-create d-flex">
                    <h3 className='main-head-pro'>Players Lobby</h3>
                </div>
                <div className="table-maintourna table-palyer">
                <table className='w-100'>
            <thead>
                <tr>
                    <th>Player Name</th>
                    <th>Role</th>
                    <th>Invitation Date</th>
                </tr>
            </thead>
            <tbody>
            <tr>
                        <td>{captainDetails.name}</td>
                        <td>Captain</td>
                        <td>{moment(captainDetails.created_at).format('DD-MM-YYYY')}</td>
                    </tr>
                {playerLobbyList.map((player, index) => (
                    <tr key={index}>
                        <td>{player.name}</td>
                        <td>PLAYER</td>
                        <td>{moment(player.created_at).format('DD-MM-YYYY')}</td>
                    </tr>
                ))}
            </tbody>
        </table>
                </div>
            </section>


        </>
    )
}
export default OtherTeamsView