import React,{useRef} from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import sigimg from "../assets/signup.png";
import Form from "react-bootstrap/Form";
import { useForm, Controller } from "react-hook-form";
import axios from "axios";
import axiosClient from "../Services/axiosClient";
import { BASE_URL, VERIFY_OTP } from "../API";
import { Navigate, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Otp() {

  const navigate = useNavigate();

  let email = localStorage.getItem("email_reset");

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue
  } = useForm();

  const onSubmit = async (data) => {
    const otpValues = Object.values(data).join("");
    let requestData = {
      email : email,
      otp : parseInt(otpValues)
    }

    try{
      const response = await axiosClient.post( BASE_URL + VERIFY_OTP , JSON.stringify(requestData))

      if(response.data.code===200){
        toast.success(response.data.message)
        navigate("/createpassword")
      }else{
        toast.error(response.data.error)
      }

    }catch(error){
      console.error(error,"err")
      if (error.response && error.response.data) {
        const errorMessage =
          error.response.data.message ||
          "An error occurred";
        toast.error(errorMessage);
      } else {
        toast.error("An error occurred");
      }
      reset();
    }

  };

  const inputRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];

  const handleInputChange = (index, value) => {
 
    if (value.length > 1) {
      value = value.slice(0, 1);
    }
    setValue(`otp${index}`, value);
    if (value.length === 1 && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    } else if (value.length === 0 && index > 0) {
      inputRefs[index - 1]?.current?.focus();}
  };


  return (
    <>
      <section className="signup-main-clas">
        <Row className=" g-0">
          {/* <Col md={6}>
            <div className="inner-img-sign login-page-clas">
              <figure>
                <img src={sigimg} className="img-fluid" alt="" />
              </figure>
            </div>
          </Col> */}
          <Col md={6}>
            <div className="upper-flex-signup reset-password">
              <div className="inner-text-fields">
                <div className="create-acc-sec">
                  <h2>OTP Verification </h2>
                  <p className="enter-text-desc">
                    Enter the verification code we sent to <br />
                    <span>{email}</span>
                  </p>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group
                      className="input-comn-styel-signup otp-main-inpts"
                      controlId="formGroupPassword"
                    >
                      {[1, 2, 3, 4, 5, 6].map((index) => (
                        <Controller
                          key={index}
                          name={`otp${index}`}
                          control={control}
                          defaultValue=""
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Form.Control
                              {...field}
                              type="number"
                              onChange={(e) => handleInputChange(index, e.target.value)}
                              ref={inputRefs[index]} 
                              maxLength={1} 
                              pattern="[0-9]"
                            />
                          )}
                        />
                      ))}
                    </Form.Group>

                    <button className="get-start-btn-comn mt-1" type="submit">
                      Verify OTP
                    </button>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
}

export default Otp;
