import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/Footer";
import { Container } from "react-bootstrap";
import { BASE_URL, PRIVACY_POLICY } from "../API";
import axiosClient from "../Services/axiosClient";

function Privacypolicy() {
  const [data, setData] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosClient.get(BASE_URL + PRIVACY_POLICY);
        if (response.status === 200) {
          setData(response.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);


  return (
    <>
      <Header />
      <section className="back-comn-sec">
        <div className="container">
          <div className="inner-banner-comn-text">
            <h2>{data?.title}</h2>
          </div>
        </div>
      </section>
      <section className="comn-padd-sec">
        <Container>
          <div className="inner-term-cond">
            <h6>{data?.title}</h6>
            <div  dangerouslySetInnerHTML={{__html : data?.description}}></div>
          </div>
        </Container>
      </section>

      <Footer />
    </>
  );
}

export default Privacypolicy;
