import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import { BASE_URL, REJECT_PLAYER_REQUEST, ACCEPT_PLAYER_REQUEST } from "../API";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Invite_Status({ isShowModal, closeModel, user_id, team_id }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleAccept = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await axios.post(
        BASE_URL + ACCEPT_PLAYER_REQUEST,
        JSON.stringify({ team_id: team_id, player_id: user_id }),
        { headers: headers }
      );

      if (response.data.status_code === 200 && response.data.status === true) {
        console.log("response.data::", response);
        setIsLoading(false);
        closeModel();
        toast.success(response.data.message);
        navigate('/myprofile',{state:{menu:1}})

      } else if (
        response.data.status_code === 400 &&
        response.data.status === false
      ) {
        setIsLoading(false);
        toast.error(response?.data?.error[0]);
      } else if (
        response.data.status_code === 403 &&
        response.data.status === false
      ) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        // console.log('response.data::',response)
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setIsLoading(false);
        const errorMessage = error.response.data.message || "An error occurred";
        toast.error(errorMessage);
      } else {
        setIsLoading(false);
        toast.error("An error occurred");
      }
    }
  };

  const handleReject = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await axios.post(
        BASE_URL + REJECT_PLAYER_REQUEST,
        JSON.stringify({ team_id: team_id, player_id: user_id }),
        { headers: headers }
      );

      if (response.data.status_code === 200 && response.data.status === true) {
        console.log("response.data::", response);
        setIsLoading(false);
        closeModel();
        toast.success(response.data.message);
        navigate('/myprofile',{state:{menu:1}})
      } else if (
        response.data.status_code === 400 &&
        response.data.status === false
      ) {
        setIsLoading(false);
        toast.error(response?.data?.error[0]);
      } else if (
        response.data.status_code === 403 &&
        response.data.status === false
      ) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        // console.log('response.data::',response)
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setIsLoading(false);
        const errorMessage = error.response.data.message || "An error occurred";
        toast.error(errorMessage);
      } else {
        setIsLoading(false);
        toast.error("An error occurred");
      }
    }
  };

  return (
    <Modal show={isShowModal} onHide={closeModel}>
      {/* <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header> */}
      <Modal.Body className="modal-padd">
        <div className="inner-ogout-btn">
          <h2>Pending Request</h2>

          <p className="text-damble-text">User request to join your team</p>
        </div>

        <div className="upper-btn-llogout-main d-flex">
          <a class="edit-butt" onClick={()=>handleAccept()}>
            ACCEPT
          </a>
          <button onClick={()=>handleReject()} class="login-head">
            REJECT
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Invite_Status;
