import React from 'react'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap'
import logo from '../Assets/Images/eu.png'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
function Footer() {
    const location = useLocation()
    const [path, setPath] = useState()

    useEffect(() => {
        const { pathname } = location;
        setPath(pathname)
    }, [])

    return (
        <>
            <section className='footer-main'>
                <Container>
                    <div className="inner-footer">
                        <Row>
                            <Col md={4}>

                                <div className="inner-footer-log">
                                    <div className="logo-foot-icon">
                                        <Link to="/">
                                            <figure>
                                                <img src={logo} alt="" />
                                            </figure>
                                        </Link>
                                        {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor massa ut metus dictum facilisis. Nunc eget erat ac nunc condimentum tempus vitae et dui. Vestibulum sem lorem.</p> */}
                                    </div>
                                </div>

                            </Col>
                            <Col md={5}>
                                <div className="inner-quick-link">
                                    <h2>Quick Links</h2>
                                    <ul>
                                        <li>
                                            {path === '/' ? <AnchorLink href="#HOME">
                                                Home
                                            </AnchorLink> :
                                                <Link to="/">
                                                    Home
                                                </Link>}
                                        </li>
                                        <li>
                                            {path === '/' ? <AnchorLink href="#ABOUT-US">
                                                About Us
                                            </AnchorLink> :
                                                <Link to="/">
                                                    About Us
                                                </Link>}

                                        </li>
                                        <li>
                                            {path === '/' ? <AnchorLink href="#HOW-IT-WORKS">
                                                How It Works
                                            </AnchorLink> :
                                                <Link to="/">
                                                    How It Works
                                                </Link>}

                                        </li>
                                        <li>
                                            {path === '/' ? <AnchorLink href='#FEATURED-COMPETITIONS'>Tournaments</AnchorLink> :
                                                <Link to="/">
                                                    Tournaments
                                                </Link>}

                                        </li>
                                        <li>
                                            {path === '/' ? <AnchorLink href="#CONTACT-US">
                                            Contact Us
                                            </AnchorLink> :
                                                <Link to="/">
                                                    Contact Us
                                                </Link>}

                                        </li>
                                    </ul>

                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="inner-quick-link ps-0">
                                    <h2>Social Links</h2>
                                    <div className="media-icons d-flex">
                                        <Link to="https://www.instagram.com/euproscene/" className='link-icons' target="_blank" rel="noopener noreferrer">
                                            <i className="fa-brands fa-instagram"></i>
                                        </Link>
                                        <Link to="https://x.com/EUProScene" className='link-icons' target="_blank" rel="noopener noreferrer"> 
                                            <i className="fa-brands fa-twitter"></i>
                                        </Link>
                                        <Link to="https://discord.gg/yqreuPAKAM" className='link-icons' target="_blank" rel="noopener noreferrer">
                                            <i className="fa-brands fa-discord"></i>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            <section>
                <div className="footer-bottom">
                    <Container>
                        <div className="inner-foot-st d-flex">
                            <p>© 2024 Logo Copyright All Right Reserved</p>
                            <div className="links-last">
                                <Link to="">
                                    Privacy Policy
                                </Link>
                                <Link to=""> Terms & Conditions
                                </Link>
                            </div>
                        </div>
                    </Container>
                </div>
            </section>
        </>
    )
}

export default Footer