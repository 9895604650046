import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios'
import Form from 'react-bootstrap/Form';
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { update } from 'firebase/database';
import { BASE_URL, GENERATE_LINK,PLAYER_LIST } from "../API";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Invite_StatusModal from './Invite_StatusModal';
import moment from "moment";

function TeammanageView({ setIsEdit, selectedTeam }) {
    const [link, setLink] = useState('');
    const [playerLobbyList,setPlayerLobbyList]=useState([]);
    const [isShowModal,setIsShowModal]=useState(false);
    const [selectedInvitationRequest,setSelectInvitationRequest]=useState('')
    const navigate = useNavigate();

    useEffect(() => {
        getSharableLink();
        getPlayerLobby();
    }, []);

    const getSharableLink = async () => {
        try {
            const token = localStorage.getItem("token");
            const headers = {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            };

            const response = await axios.get(BASE_URL + GENERATE_LINK + "?id=" + selectedTeam.id, {
                headers: headers,
            });
            if (response.data.status_code === 200 && response.data.status === true) {
                setLink(response.data.url)

            } else if (
                response.data.status_code === 400 &&
                response.data.status === false
            ) {
                toast.error(response?.data?.error[0]);
            } else if (
                response.data.status_code === 403 &&
                response.data.status === false
            ) {
                localStorage.removeItem("user");
                localStorage.removeItem("token");
                navigate("/login");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            if (error.response && error.response.data) {
                const errorMessage = error.response.data.message || "An error occurred";
                toast.error(errorMessage);
            } else {
                toast.error("An error occurred");
            }
        }
    };


    // const copyLink = async () => {
    //     const link = document.getElementById('linkToCopy').href;
    //     console.log('dd',link)
    //     try {
    //         await navigator.clipboard.writeText(link);
    //         toast.success('Link copied to clipboard!');

    //     } catch (err) {
    //         toast.error('Failed to copy: ', err);
    //     }
    // }

    const copyLink = async (link) => {
        if(!link){
              return false;  
        }
        try {
            const el = document.createElement('textarea');
            el.value = link;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            toast.success('Link copied to clipboard!');
        } catch (err) {
            toast.error('Failed to copy: ' + err);
            console.error('Failed to copy:', err);
        }
    };

    const getPlayerLobby=async()=>{
        const token=localStorage.getItem('token')
        try{
            const headers = {Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            };
            const response = await axios.get(BASE_URL + PLAYER_LIST + '?team_id=' + selectedTeam.id, {
                headers: headers,
            });
            console.log(response.data.players)
              if (response.data.status_code === 200 && response.data.status === true) {
                setPlayerLobbyList(response.data.players)
                //   toast.success(response.data.message);
              } else if (
                response.data.status_code === 400 &&
                response.data.status === false
              ) {
                toast.error(response?.data?.error[0]);
              } else if (
                response.data.status_code === 403 &&
                response.data.status === false
              ) {
                localStorage.removeItem("user");
                localStorage.removeItem("token");
              } else {
                // console.log('response.data::',response)
                toast.error(response.data.message);
              }
            } catch (error) {
              if (error.response && error.response.data) {
                const errorMessage = error.response.data.message || "An error occurred";
                toast.error(errorMessage);
              } else {
                toast.error("An error occurred");;
              }
            }
    }

    const updatePlayerStatus=(player)=>{
        setSelectInvitationRequest(player)
    }
    const closeModel=()=>{setIsShowModal(false)  }
    const openModel=(player)=>{
        updatePlayerStatus(player)
        setIsShowModal(true)  
    }

    return (
        <>
            {isShowModal && <Invite_StatusModal isShowModal={isShowModal} closeModel={closeModel} user_id={selectedInvitationRequest.id} team_id={selectedTeam.id} />}
            <section>
                <div className="edit-profile-main">
                    <div className="upper-btn-create d-flex">
                        <h3 className='main-head-pro mb-0'>Team Management</h3>
                        <button className="edit-butt" onClick={() => setIsEdit(true)}>Edit Team</button>
                    </div>
                    <div className="upper-form-main">
                        <div className="form-main-name-detal team-detail-main">
                            <div className="team-name-all">
                                <div className="team-name">
                                    <h6>Team Name</h6>
                                    <p>{selectedTeam.team_name}</p>
                                </div>

                                <div className="team-name">
                                    <h6>Total Players</h6>
                                    <p>{selectedTeam.total_players} Players</p>
                                </div>
                            </div>
                            <div className="team-name team-link-main">
                                <a id="linkToCopy" className='mb-3' href={link} style={{ pointerEvents: 'none'}}>Team link</a>
                                <button id="copyButton" className='edit-butt' onClick={()=>copyLink(link)}>Copy</button>
                            </div>



                            {/* <div className="discp">
                                <div className="team-name">
                                    <h6>Description</h6>
                                    <p>Lorem Ipsum is simply dummy text of the printing
                                        and typesetting industry.</p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

            </section>

            <section className='view-table-main'>
                <div className="upper-btn-create d-flex">
                    <h3 className='main-head-pro'>Players Lobby</h3>
                </div>
                <div className="table-maintourna table-palyer">
                <table className='w-100'>
            <thead>
                <tr>
                    <th>Player Name</th>
                    <th>Role</th>
                    <th>Invitation Date</th>
                    <th>Invitation Time</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {playerLobbyList.map((player, index) => (
                    <tr key={index}>
                        <td>{player.name}</td>
                        <td>player</td>
                        <td>{moment(player.created_at).format('DD-MM-YYYY')}</td>
                        <td>{moment(player.created_at).format('HH:mm')}</td>
                        {/* <td>{player.created_at}</td>
                        <td>{player.updated_at}</td> */}
                        <td>
                        {player.is_accepted ? (
                        <button className='accept-btn' disabled >
                            Accepted
                        </button>
                    ) : (
                        <button className='pending-btn' onClick={() => openModel(player)}>
                            Pending
                        </button>
                    )}
                    </td>
                    </tr>
                ))}
            </tbody>
        </table>
                </div>
            </section>


        </>
    )
}

export default TeammanageView