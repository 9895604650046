import React, { useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/Footer";
import { Container } from "react-bootstrap";
import green from "../Assets/Images/green.png";
import { Link } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import mypro from "../Assets/Images/Group (3).svg";
import change from "../Assets/Images/Group (4).svg";
import log from "../Assets/Images/Frame (4).svg";
import team from "../Assets/Images/Frame (3).svg";
import dia from "../Assets/Images/Frame (2).svg";
import cred from "../Assets/Images/Frame (1).svg";
import ff from "../Assets/Images/ff.svg";
import Modal from "react-bootstrap/Modal";
// import MyProfilein from '../components/MyProfilein'
// import EditProfile from '../components/EditProfile'
import Button from "react-bootstrap/Button";
import ChangePassword from "../components/ChangePassword";
import Editprofile from "../components/Editprofile";
import ProfileDetail from "../components/ProfileDetail";
import MyTournament from "../components/MyTournament";
import Credits from "../components/Credits";
import Subscription from "../components/Subscription";
import TeamManage from "../components/TeamManage";
import OtherTeams from "../components/OtherTeams";
import CreateTeam from "../components/Createteam";
import { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { BASE_URL, GET_USER_DETAILS, TEAM_LIST,OTHER_TEAM_LIST, LOG_OUT } from "../API";
import { useNavigate,useLocation } from "react-router-dom";
import Loader from "../components/Loader";
import Success from "../components/Success";
import RejectPayment from "../components/RejectPayment";
import PaymentSuccessModel from "../components/Success"
import TeammanageView from "../components/TeammanageView";
function Myprofile() {
  const navigate = useNavigate();
  const location=useLocation()
  const [activeMenu, setActiveMenu] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [userData, setUserData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [teamList, setTeamsList] = useState([]);
  const [otherTeamsList, setOtherTeamsList] = useState([]);

  useEffect(() => {
    setIsOpen(false);
  }, [activeMenu]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const logOut = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.post(
        BASE_URL + LOG_OUT,
        JSON.stringify({}),
        { headers: headers }
      );

      if (response.data.status_code === 200 && response.data.status === true) {
        toast.success(response.data.message);
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
        setIsLoading(false);
      } else if (
        response.data.status_code === 400 &&
        response.data.status === false
      ) {
        toast.error(response?.data?.error[0]);
        setIsLoading(false);
      } else if (
        response.data.status_code === 403 &&
        response.data.status === false
      ) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
        setIsLoading(false);
      } else {
        // console.log('response.data::',response)
        toast.error(response.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message || "An error occurred";
        toast.error(errorMessage);
        setIsLoading(false);
      } else {
        toast.error("An error occurred");
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    console.log(location.state)
    if(location.state){
      setActiveMenu(location.state.menu)
    }
    const user=localStorage.getItem("user")
    if(!user){
      navigate('/login')
      return
    }
    getProfileDetails();
    getMyTeams();
    getOtherTeams();
  }, [location.state]);

  const getProfileDetails = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.get(BASE_URL + GET_USER_DETAILS, {
        headers: headers,
      });

      if (response.data.status_code === 200 && response.data.status === true) {
        setUserData(response.data.user);
        localStorage.setItem("user", JSON.stringify(response?.data?.user));

        setIsLoading(false);

        //   toast.success(response.data.message);
      } else if (
        response.data.status_code === 400 &&
        response.data.status === false
      ) {
        toast.error(response?.data?.error[0]);
        setIsLoading(false);
      } else if (
        response.data.status_code === 403 &&
        response.data.status === false
      ) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        // console.log('response.data::',response)
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message || "An error occurred";
        setIsLoading(false);
        toast.error(errorMessage);
      } else {
        toast.error("An error occurred");
        setIsLoading(false);
      }
    }
  };

  const getMyTeams = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.get(BASE_URL + TEAM_LIST, {
        headers: headers,
      });

      if (response.data.status_code === 200 && response.data.status === true) {
        setTeamsList(response.data.data);
        setIsLoading(false);

        //   toast.success(response.data.message);
      } else if (
        response.data.status_code === 400 &&
        response.data.status === false
      ) {
        toast.error(response?.data?.error[0]);
        setIsLoading(false);
      } else if (
        response.data.status_code === 403 &&
        response.data.status === false
      ) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        // console.log('response.data::',response)
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message || "An error occurred";
        setIsLoading(false);
        toast.error(errorMessage);
      } else {
        toast.error("An error occurred");
        setIsLoading(false);
      }
    }
  };

  const getOtherTeams = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.get(BASE_URL + OTHER_TEAM_LIST, {
        headers: headers,
      });

      if (response.data.status_code === 200 && response.data.status === true) {
        setOtherTeamsList(response.data.data);
        setIsLoading(false);

        //   toast.success(response.data.message);
      } else if (
        response.data.status_code === 400 &&
        response.data.status === false
      ) {
        toast.error(response?.data?.error[0]);
        setIsLoading(false);
      } else if (
        response.data.status_code === 403 &&
        response.data.status === false
      ) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        // console.log('response.data::',response)
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message || "An error occurred";
        setIsLoading(false);
        toast.error(errorMessage);
      } else {
        toast.error("An error occurred");
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      {/* <Success /> */}
      {/* <RejectPayment /> */} 
      <Loader isLoading={isLoading} />
      <Header userDetails={userData} />
      <section className="back-profile">
        <Container>
          <div className="inner-banner-prof">
            <img src={green} alt="" />
            {/* <p className="subtitle-hero"># World Class ESports & Gaming Site</p> */}
            <h1>
              <span className="span-heromain">My Account</span>
            </h1>
          </div>
        </Container>
      </section>

      <section>
        <div className="my-profile comn-padd">
          <Container>
            <div className="inner-acount">
              <div className="row">
                <div className="col-lg-3">
                  <div className="inner-left-tabs">
                    <div className="uuper-namne-tag d-flex">
                      <div className="upper-main-edit-link">
                        <h2 className="profile-name">My Account</h2>
                      </div>
                    </div>

                    <div className="pofile-names">
                      <ul className="p-0 m-0">
                        <li className={activeMenu === 1 ? "active" : " "}>
                          <div
                            className="my-profile-name d-flex"
                            onClick={() => setActiveMenu(1)}
                          >
                            <figure>
                              <img src={mypro} alt="" />
                            </figure>
                            <p>My Profile</p>
                          </div>
                        </li>
                        <li className={activeMenu === 2 ? "active" : " "}>
                          <div
                            className="my-profile-name d-flex"
                            onClick={() => setActiveMenu(2)}
                          >
                            <figure>
                              <img src={change} alt="" />
                            </figure>
                            <p>Change Password</p>
                          </div>
                        </li>
                        <li className={activeMenu === 3 ? "active" : " "}>
                          <div
                            className="my-profile-name d-flex"
                            onClick={() => setActiveMenu(3)}
                          >
                            <figure>
                              <img src={ff} alt="" />
                            </figure>
                            <p>My Tournaments</p>
                          </div>
                        </li>
                        <li className={activeMenu === 4 ? "active" : " "}>
                          <div
                            className="my-profile-name d-flex"
                            onClick={() => setActiveMenu(4)}
                          >
                            <figure>
                              <img src={cred} alt="" />
                            </figure>
                            <p>Credits</p>
                          </div>
                        </li>
                        <li className={activeMenu === 5 ? "active" : " "}>
                          <div
                            className="my-profile-name d-flex"
                            onClick={() => setActiveMenu(5)}
                          >
                            <figure>
                              <img src={dia} alt="" />
                            </figure>
                            <p>VIP Subscription</p>
                          </div>
                        </li>
                        <li className={activeMenu === 6 ? "active" : " "}>
                          <div
                            className="my-profile-name d-flex"
                            onClick={() => setActiveMenu(6)}
                          >
                            <figure>
                              <img src={team} alt="" />
                            </figure>
                            <p>Team Management</p>
                          </div>
                        </li>
                        <li className={activeMenu === 7 ? "active" : " "}>
                          <div
                            className="my-profile-name d-flex"
                            onClick={() => setActiveMenu(7)}
                          >
                            <figure>
                              <img src={team} alt="" />
                            </figure>
                            <p>My Participations</p>
                          </div>
                        </li>
                        <li>
                          <div
                            className="my-profile-name d-flex"
                            onClick={handleShow}
                          >
                            <figure>
                              <img src={log} alt="" />
                            </figure>
                            <p>Log-out</p>
                          </div>

                          <Modal
                            className="modal-logout"
                            show={show}
                            onHide={handleClose}
                          >
                            {/* <Modal.Header className="border-0" closeButton>

                            </Modal.Header> */}
                            <Modal.Body className="modal-padd">
                              <div className="inner-ogout-btn">
                                <h2>Are You sure you want to log out ?</h2>
                                <p className="text-damble-text">
                                  The action you are going to perform is
                                  irreversible please comfirm! Are you sure that
                                  you want to logout?
                                </p>
                              </div>
                              <div className="upper-btn-llogout-main d-flex">
                                <Link onClick={()=>handleClose()} className="login-head">
                                  Close
                                </Link>
                                <a className="edit-butt" onClick={() => logOut()}>
                                  Log Out
                                </a>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9">
                  <div className="upper-main-cmp ">
                    {activeMenu === 1 && isOpen === false && (
                      <ProfileDetail
                        setIsOpen={setIsOpen}
                        userDetails={userData}
                      />
                    )}
                    {activeMenu === 1 && isOpen === true && (
                      <Editprofile
                        userDetails={userData}
                        getUserDetails={getProfileDetails}
                      />
                    )}
                    {activeMenu === 2 && isOpen === false && <ChangePassword />}
                    {activeMenu === 3 && isOpen === false && <MyTournament />}
                    {activeMenu === 4 && isOpen === false && (
                      <Credits
                        userDetails={userData}
                        getUserDetails={getProfileDetails}
                      />
                    )}
                    {activeMenu === 5 && isOpen === false && <Subscription />}
                    {activeMenu === 6 && isOpen === false && (
                      <TeamManage
                        setIsOpen={setIsOpen}
                        userDetails={userData}
                        teamList={teamList}
                        getMyTeams={getMyTeams}
                      />
                    )}
                    {activeMenu === 6 && isOpen === true && (
                      <CreateTeam
                        userDetails={userData}
                        getMyTeams={getMyTeams}
                        setIsOpen={setIsOpen}
                      />
                    )}
                       {activeMenu === 7 && isOpen === false && (
                      <OtherTeams
                      setIsOpen={setIsOpen}
                      userDetails={userData}
                      teamList={otherTeamsList}
                      getMyTeams={getMyTeams}
                    />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </section>
      
      <Footer />
    </>
  );
}

export default Myprofile;
