import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "axios";
import { BASE_URL, TEAM_LIST, ADD_TOURNAMENT_PARTICIPANTS } from "../API";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Tournament from "../pages/Tournament";

function EnrollNowModal({ isShowModal, closeModel, tournament_id }) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [teamList, setTeamsList] = useState([]);
  const [selectedTeamList, setSelectedTeamList] = useState([]);
  useEffect(() => {
    getMyTeams();
  }, []);

  const getMyTeams = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.get(BASE_URL + TEAM_LIST, {
        headers: headers,
      });

      if (response.data.status_code === 200 && response.data.status === true) {
        setTeamsList(response.data.data);
        setIsLoading(false);

        //   toast.success(response.data.message);
      } else if (
        response.data.status_code === 400 &&
        response.data.status === false
      ) {
        toast.error(response?.data?.error[0]);
        setIsLoading(false);
      } else if (
        response.data.status_code === 403 &&
        response.data.status === false
      ) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        // console.log('response.data::',response)
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message || "An error occurred";
        setIsLoading(false);
        toast.error(errorMessage);
      } else {
        toast.error("An error occurred");
        setIsLoading(false);
      }
    }
  };

  const handleAccept = async () => {
    const team = JSON.parse(document.getElementById("teams").value);
    const team_id = team.id,
      captain_id = team.captain_id;
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await axios.post(
        BASE_URL + ADD_TOURNAMENT_PARTICIPANTS,
        JSON.stringify({
          tournament_id: tournament_id,
          team_id: team_id,
          captain_id: captain_id,
        }),
        { headers: headers }
      );

      if (response.data.status_code === 200 && response.data.status === true) {
        console.log("response.data::", response);
        setIsLoading(false);
        toast.success(response.data.message);
      } else if (
        response.data.status_code === 400 &&
        response.data.status === false
      ) {
        setIsLoading(false);
        toast.error(response?.data?.error[0]);
      } else if (
        response.data.status_code === 403 &&
        response.data.status === false
      ) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        // console.log('response.data::',response)
        toast.error(response.data.message);
      }
      closeModel();
    } catch (error) {
      if (error.response && error.response.data) {
        setIsLoading(false);
        const errorMessage = error.response.data.message || "An error occurred";
        toast.error(errorMessage);
      } else {
        setIsLoading(false);
        toast.error("An error occurred");
      }
      closeModel();
    }
  };
  return (
    <Modal show={isShowModal} onHide={closeModel}>
      <Modal.Body className="modal-padd">
        <div className="inner-ogout-btn">
          <h2>Tournament Enrollment</h2>
          {teamList.length? <Form.Group className="comn-inpt-main mb-4">
          <Form.Label>Select team: </Form.Label>
                <Form.Select id="teams">
              {teamList.map((team,key) => (
                <option key={key} value={JSON.stringify(team)}>
                  {" "}
                  {team.team_name}{" "}
                </option>
              ))}
            </Form.Select>
          </Form.Group>:<p className="text-damble-text">No teams, please create teams first!</p>}
        </div>
        {teamList.length?<div className="upper-btn-llogout-main d-flex">
          <button onClick={()=>closeModel()} class="login-head">
            No
          </button>
          <a class="edit-butt" onClick={() => handleAccept()}>
            Yes
          </a>
        </div>:<></>}
      </Modal.Body>
    </Modal>
  );
}

export default EnrollNowModal;
