import React, { useState, useEffect } from "react";
import axios from "axios";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { Container } from "react-bootstrap";
import Pagination from 'react-bootstrap/Pagination'
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BASE_URL, USER_ENROLLED_TOURNAMENT_LIST } from "../API";
import Loader from "./Loader";
import pubg from "../Assets/Images/pubg.png";
import cred from "../Assets/Images/crad5.png";
import moment from "moment";

const ITEMS_PER_PAGE = 5;

function MyTournament() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [getUserTournaments, setGetUserTournaments] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    getTournamentList();
  }, [currentPage]);

  const getTournamentList = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const user = JSON.parse(localStorage.getItem("user"));
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.get(
        BASE_URL + USER_ENROLLED_TOURNAMENT_LIST + "?captain_id=" + user.id,
        {
          headers: headers,
        }
      );

      if (response.data.status_code === 200 && response.data.status === true) {
        setIsLoading(false);
        // console.log(response.data.data)
        const getUserTournaments = response.data.data;
        const totalCount = getUserTournaments.length;
        setTotalPages(Math.ceil(totalCount / ITEMS_PER_PAGE));
        const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
        const currentItems = getUserTournaments.slice(
          startIndex,
          startIndex + ITEMS_PER_PAGE
        );
        setGetUserTournaments(currentItems);

        //   toast.success(response.data.message);
      } else if (
        response.data.status_code === 400 &&
        response.data.status === false
      ) {
        toast.error(response?.data?.error[0]);
        setIsLoading(false);
      } else if (
        response.data.status_code === 403 &&
        response.data.status === false
      ) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        // console.log('response.data::',response)
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message || "An error occurred";
        setIsLoading(false);
        toast.error(errorMessage);
      } else {
        toast.error("An error occurred");
        setIsLoading(false);
      }
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const MoveOnViewDetails = (element) => {
    // console.log(element)
    navigate("/viewtournament", { state: { element } });
  };

  return (
    <>
     <Loader isLoading={true} />
      <section>
        <Container>
          <div className="edit-profile-main  mt-4">
            <h3 className="main-head-pro">MY TOURNAMENT</h3>
            <div className="upper-form-main">
              <div className="form-main-name-detal">
                <div className="upper-my-tournament-table">
                  <table className="w-100">
                    <tr>
                      <th>Tournament Name</th>
                      <th>Team Size</th>
                      <th>Deadline Date</th>
                      <th>Deadline Time</th>
                      <th>Winning Prize</th>
                    </tr>
                    {getUserTournaments && getUserTournaments.length ? (
                      getUserTournaments.map((element, index) => {
                        return (
                          <tr key={index}>
                            <td onClick={() => MoveOnViewDetails(element)} style={{ cursor: 'pointer',color:"#E83424" }}>{element.tournament_name}</td>
                            <td>{element.team_size}</td>
                            <td>{moment(element.tournament_end_date).format('DD-MM-YYYY')}</td>
                            <td>{moment(element.tournament_end_date).format('HH:mm')}</td>
                            <td>{element.winning_prize}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="text-center" colSpan={5}>No Tournaments yet</td>
                      </tr>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      {getUserTournaments && getUserTournaments.length ?
      <Pagination>
                <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage==1}/>
                <Pagination.Prev onClick={() => handlePageChange(Math.max(currentPage - 1, 1))} disabled={currentPage==1}/>
                {Array.from({ length: totalPages }).map((_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))} disabled={currentPage==totalPages}/>
                <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage==totalPages}/>
              </Pagination>:<></>}
    </>
  );
}

export default MyTournament;
