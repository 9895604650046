export const BASE_URL = "https://euproscene.com/admin/public"
// export const BASE_URL = "http://admin.smscentral.org/Backend/public"

export const BASE_URL_IMAGE_BLOG = "http://49.249.236.30:3025/hadi/images"
// export const BASE_URL_IMAGE_BLOG = "http://admin.smscentral.org/Backend/public/images"

export const IMAGE_BASE_URL = "https://euproscene.com/admin/public/storage/"
// export const BASE_URL_IMAGE_HOMEPAGE = "http://admin.smscentral.org/Backend/public/storage"


export const SIGN_UP = "/api/register"
export const SIGN_IN ="/api/login"
export const FORGET_PASSWORD ="/api/forget-password"
export const RESET_PASSWORD = "/api/reset-password"
export const CHANGE_PASSWORD = "/api/change-password"
export const GET_USER_DETAILS = "/api/user-profile"
export const UPDATE_USER_PROFILE = "/api/update-user-profile"
export const LOG_OUT ="/api/logout"
export const FILE_UPLOAD = "/api/update-user-profile-picture" 
export const TOURNAMENT_LIST = "/api/tournaments-list"
export const TOURNAMENT_DETAILS = "/api/tournament-details"
export const GAME_LIST = "/api/games-list"
export const CREDITS_PACKAGE ="/api/credit-packages"
export const SAVE_TRANSACTION = '/api/save-transaction'
export const USER_CREDIT_TRANSACTIONS = "/api/user-credit-transactions"
export const CREATE_TEAM = "/api/create-team"
export const TEAM_LIST = '/api/teams-list'
export const OTHER_TEAM_LIST = '/api/participate-teams-list'
export const EDIT_TEAM = "/api/update-team"
export const DELETE_TEAM = "/api/delete-team"
export const GENERATE_LINK = "/api/generate-invite-player-link"
export const INVITED_PLAYER = "/api/invite-player-form-submit"
export const PLAYER_LIST = "/api/player-list"
export const ACCEPT_PLAYER_REQUEST = "/api/accept-player"
export const REJECT_PLAYER_REQUEST = "/api/reject-player"
export const ADD_TOURNAMENT_PARTICIPANTS = "/api/add-tournament-participants"
export const USER_ENROLLED_TOURNAMENT_LIST = "/api/user-enroll-tournament-list"
export const GET_SUBSCRIPTION_PLANS = "/api/subscription-details"
export const SAVE_SUBSCRIPTION_PLAN = "/api/save-subscription-transaction"
export const GET_SUBSCRIPTION_DETAILS = "/api/user-subscription-details"



export const EMAIL_RESET = "/api/email"
export const VERIFY_OTP ="/api/verify-otp"
export const CONTACT_US = "/api/contactus"
export const NEWS_LETTER = "/api/newsletter"
export const FOOTER ="/api/get-page-data/footer"
export const TERMS_CONDITIONS = "/api/page/terms-and-conditions"
export const PRIVACY_POLICY = "/api/page/privacy-policy"
export const HOME_PAGE ="/api/get-page-data/homepage"
export const BLOG = "/api/getblogs"
export const BLOG_DETAILS = "/api/blogdetails"
export const ABOUT_US = "/api/get-page-data/aboutus"
export const NUMBER_REACTIVATION = "/api/get-page-data/numberreactivation"
export const MULTISERVICE = "/api/get-page-data/multiservice"
export const VIRTUAL_MOBILE_NUMBER_RENT = "/api/get-page-data/virtualmobilenumberrent"
export const SMS_ACTIVATION = "/api/get-page-data/smsactivation"
export const VERIFICATION_BY_NUMBER = "/api/get-page-data/verificationbynumber"





