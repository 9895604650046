import React from "react";
import Header from "../components/header";
import Footer from "../components/Footer";
import { useForm } from "react-hook-form";
import { Container, Form, Row, Col } from "react-bootstrap";
import axiosClient from "../Services/axiosClient";
import { CONTACT_US, BASE_URL } from "../API";
import { toast } from "react-toastify";

function Contactus() {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {

    try {
      const response = await axiosClient.post(BASE_URL + CONTACT_US, JSON.stringify(data));
      if (response?.status === 200) {
        toast.success("The Contact-Us form has been sent successfully");
        reset()
      } else {
        // Show error toast
        if (response?.data && response?.data?.error) {
          const errors = response?.data?.error;
          const errorMessage = Object.values(errors).flat().join('\n');
          toast.error(errorMessage);
        } else {
          toast.error("Failed to send the Contact-Us form. Please try again later.");
        }
      }
    } catch (error) {
      if (error?.response?.data?.error) {
        const errorMessages = Object.keys(error.response.data.error)
          .map(field => error.response.data.error[field][0])
          .join('\n\n'); // Add double line break between error messages
        toast.error(errorMessages);
        // console.log(error.response.data.error);
      }
    }
  };

  return (
    <>
      <Header />
      <section className="back-comn-sec">
        <div className="container">
          <div className="inner-banner-comn-text">
            <h2>Contact Us</h2>
          </div>
        </div>
      </section>

      <section className="comn-padd-sec">
        <Container>
          <div className="inner-contact-us">
            <h3 className="send-mesg-heaader">Send Us A Message</h3>
            <p className="contact-us-discrip">
              We're here to help – send us a message and we'll get back to you promptly!
            </p>

            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={6}>
                  <Form.Group
                    className="input-comn-styel-contactus"
                    controlId="firstName">
                    <Form.Label>First Name*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your First name"
                      {...register("name", { required: true })}
                    />
                    {errors.name && (
                      <span className="text-danger">
                        First Name is required
                      </span>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="input-comn-styel-contactus"
                    controlId="lastName">
                    <Form.Label>Last Name*</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your Last Name"
                      {...register("last_name")}
                    />
                    {errors.last_name && (
                      <span className="text-danger">
                        Last Name is required
                      </span>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="input-comn-styel-contactus"
                    controlId="email">
                    <Form.Label>Email*</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter your email"
                      {...register("email", { required: true })}
                    />
                    {errors.email && (
                      <span className="text-danger">Email is required</span>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="input-comn-styel-contactus"
                    controlId="phoneNumber">
                    <Form.Label>Phone Number*</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter your phone number"
                      {...register("contact_number", { required: true })}
                    />
                    {errors.contact_number && (
                      <span className="text-danger">
                        Phone Number is required
                      </span>
                    )}
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group
                    className="input-comn-styel-contactus"
                    controlId="message">
                    <Form.Label>Message*</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Enter your message"
                      style={{ height: "178px" }}
                      {...register("message", { required: true })}
                    />
                    {errors.message && (
                      <span className="text-danger">Message is required</span>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <div className="upper-btn-conattc-us text-center">
                <button className="get-start-btn-comn" type="submit">
                  Send Message
                </button>
              </div>
            </Form>
          </div>
        </Container>
      </section>
      <Footer />
    </>
  );
}

export default Contactus;
