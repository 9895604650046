
import './App.css';
import './Responsive.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route ,HashRouter} from "react-router-dom";
import Homepage from './pages/Homepage.jsx';
import Login from './pages/Login';
import Resetpassword from './pages/Resetpassword';
import Signup from './pages/Signup';
import Termscondition from './pages/Termscondition';
import Privacypolicy from './pages/Privacypolicy';
import Contactus from './pages/Contactus';
import Blog from './pages/Blog';
import Blogdetail from './pages/Blogdetail'
import Otp from './pages/Otp';
import Createnewpassword from './pages/Createnewpassword';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Myprofile from './pages/Myprofile';
import AboutUs from './pages/AboutUs';
import Game from './pages/Game.jsx';
import Tournament from './pages/Tournament.jsx';
import ViewTournament from './pages/ViewTournament.jsx';
import ForgotPassword from './pages/ForgotPassword.jsx';
function App() {
  return (

    <HashRouter>
      <ToastContainer />
      <Routes>
        <Route  path='/' index element={<Homepage />} />
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<Signup />} />
        <Route path='/resetpassword' element={<Resetpassword />} />
        <Route path='/termconditions' element={<Termscondition />} />
        <Route path='/privacypolicy' element={<Privacypolicy />} />
        <Route path='/contactus' element={<Contactus />} />
        {/* <Route path='/blog' element={<Blog />} /> */}
        <Route path='/otp' element={<Otp />} />
        <Route path='/createpassword' element={<Createnewpassword />} />
        <Route path='/myprofile' element={<Myprofile />} />
        {/* <Route path='/blogDetail' element={<Blogdetail/>} /> */}
        <Route path='/aboutus' element={<AboutUs/>} />
        <Route path='/forgotpassword' element={<ForgotPassword/>} />
        <Route path='/game' element={<Game/>} />
        <Route path='/tournament' element={<Tournament/>} />
        <Route path='/viewtournament' element={<ViewTournament/>} />
        <Route path='/'/>
      </Routes>
    </HashRouter>
  );
}

export default App;
