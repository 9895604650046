import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { Container } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { BASE_URL, CHANGE_PASSWORD } from "../API";
import axiosClient from "../Services/axiosClient";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from 'axios'; // Make sure axios is imported
import Loader from "../components/Loader";

function ChangePassword() {
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    reset,
    register,
  } = useForm();
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };
  
      const response = await axios.post(
        BASE_URL + CHANGE_PASSWORD,
        JSON.stringify(data),
        { headers: headers } 
      );
  
      if (response.data.status_code === 200 && response.data.status === true) {
        setIsLoading(false);
        toast.success(response.data.message);
      } else if (
        response.data.status_code === 400 &&
        response.data.status === false
      ) {
        setIsLoading(false);
        toast.error(response?.data?.error[0]);
      }else if(    
        response.data.status_code === 403 &&
        response.data.status === false){
          localStorage.removeItem('user');
          localStorage.removeItem('token');
          navigate('/login');
      }else {
        // console.log('response.data::',response)
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setIsLoading(false);
        const errorMessage = error.response.data.message || "An error occurred";
        toast.error(errorMessage);
      } else {
        setIsLoading(false);
        toast.error("An error occurred");
      }
      reset();
    }
  };
  return (
    <>
        <Loader isLoading={isLoading}/>
      <section>
  
          <div className="edit-profile-main">
            <h3 className="main-head-pro">Change Password</h3>
            <div className="upper-form-main">
              <div className="form-main-name-detal">
                <Form
                  className="form-clas-login"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Row>
                    <Col md={12} className="comn-inpt-main">
                      {/* <Form.Group controlId="formGroupEmail">
                        <Form.Label>Current Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter Current Password"
                        />
                      </Form.Group> */}
                      <Form.Group
                        className=""
                        controlId="formGroupPassword"
                      >
                        <Form.Label className="label-clas-comon">
                          Current Password<span>*</span>
                        </Form.Label>
                        <Controller
                          name="current_password"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Current Password is required",
                            minLength: {
                              value: 8,
                              message:
                                "Current Password must be at least 8 characters long",
                            },
                            validate: {
                              hasUppercase: (value) =>
                                /[A-Z]/.test(value) ||
                                "Current Password must contain at least one uppercase letter",
                              hasLowercase: (value) =>
                                /[a-z]/.test(value) ||
                                "Current Password must contain at least one lowercase letter",
                              hasDigit: (value) =>
                                /\d/.test(value) ||
                                "Current Password must contain at least one digit",
                              hasSpecialChar: (value) =>
                                /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(
                                  value
                                ) ||
                                "Password must contain at least one special character",
                            },
                          }}
                          render={({ field }) => (
                            <>
                              <Form.Control
                                {...field}
                                type="password"
                                className="input-comn-cls"
                                placeholder="Enter Your Current Password"
                              />
                              {errors.current_password && (
                                <span className="text-danger">
                                  {errors.current_password.message}
                                </span>
                              )}
                            </>
                          )}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={12} className="comn-inpt-main">
                      {/* <Form.Group controlId="formGroupEmail">
                        <Form.Label>New Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter New Password"
                        />
                      </Form.Group> */}
                      <Form.Group
                        className=""
                        controlId="formGroupPassword"
                      >
                        <Form.Label className="label-clas-comon">
                          New Password<span>*</span>
                        </Form.Label>
                        <Controller
                          name="new_password"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "New Password is required",
                            minLength: {
                              value: 8,
                              message:
                                "New Password must be at least 8 characters long",
                            },
                            validate: {
                              hasUppercase: (value) =>
                                /[A-Z]/.test(value) ||
                                "New Password must contain at least one uppercase letter",
                              hasLowercase: (value) =>
                                /[a-z]/.test(value) ||
                                "New Password must contain at least one lowercase letter",
                              hasDigit: (value) =>
                                /\d/.test(value) ||
                                "New Password must contain at least one digit",
                              hasSpecialChar: (value) =>
                                /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(
                                  value
                                ) ||
                                "New must contain at least one special character",
                            },
                          }}
                          render={({ field }) => (
                            <>
                              <Form.Control
                                {...field}
                                type="password"
                                className="input-comn-cls"
                                placeholder="Enter Your New Password"
                              />
                              {errors.new_password && (
                                <span className="text-danger">
                                  {errors.new_password.message}
                                </span>
                              )}
                            </>
                          )}
                        />
                      </Form.Group>
                    </Col>

                    <Col md={12} className="comn-inpt-main">
                      {/* <Form.Group controlId="formGroupEmail">
                        <Form.Label>Confirm Password</Form.Label>
                        <Form.Control
                          type="password"
                          placeholder="Enter Confirm Password"
                        />
                      </Form.Group> */}
                      <Form.Group
                        className=""
                        controlId="formGroupConfirmPassword"
                      >
                        <Form.Label className="label-clas-comon">
                          Confirm Password<span>*</span>
                        </Form.Label>
                        <Controller
                          name="confirm_password"
                          control={control}
                          defaultValue=""
                          rules={{
                            required: "Confirm Password is required",
                            validate: (value) =>
                              value === getValues("new_password") ||
                              "Passwords do not match",
                          }}
                          render={({ field }) => (
                            <>
                              <Form.Control
                                {...field}
                                type="password"
                                className="input-comn-cls"
                                placeholder="Enter Your Confirm Password"
                              />
                              {errors.confirm_password && (
                                <span className="text-danger">
                                  {errors.confirm_password.message}
                                </span>
                              )}
                            </>
                          )}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="upper-btn-login-page text-start mt-2">
                    <button className="edit-butt" type="submit">Change Password</button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
      
      </section>
    </>
  );
}

export default ChangePassword;
