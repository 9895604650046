import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Header from '../components/header';
import Footer from '../components/Footer';
import { toast } from "react-toastify";
import backborder from '../Assets/Images/back.svg'
import peopleimg from '../Assets/Images/reove.png'
import secthird from '../Assets/Images/sec3.png'
import gorup2 from '../Assets/Images/Group (2).png'
import hexa from '../Assets/Images/hexa.png'
import head from '../Assets/Images/head.png'
import tema1 from '../Assets/Images/team1.png'
import team2 from '../Assets/Images/team2.png'
import vs from '../Assets/Images/vs.png'
import lock from '../Assets/Images/lock.png'
import log from '../Assets/Images/log.png'
import logo from "../Assets/Images/eu.png";
import sec from '../Assets/Images/sec.png'
import UserInviteModel from '../components/UserInviteModel';
import { BASE_URL, IMAGE_BASE_URL, TOURNAMENT_LIST } from "../API";
import { useNavigate, useLocation } from 'react-router-dom';
import EnrollNowModal from "../components/EnrollNowModal";



function Homepage() {
  const location = useLocation()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false);
  const [tournamentList, setTournamentList] = useState([]);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")))
  const [invitedUser, setInvitedUser] = useState(false);
  const [invitedUserParams, setInvitedUserParams] = useState({});
  const [isShowModal, setIsShowModal] = useState(false);
  const [tournamentState, setTournamentState] = useState('all');
  const [selectedTournamentId, setSelectedTournamentId] = useState();

  useEffect(() => {
    get_link_params();
    getTournamentList();
    window.scrollTo(0, 0);
    // verify_user();
  }, [])

  const get_link_params = () => {
    const queryparameter = new URLSearchParams(window.location.search)
    if (queryparameter.size) {
      const params = {};
      params.user_id = queryparameter.get('user_id');
      params.team_id = queryparameter.get('team_id');
      params.team_slug = queryparameter.get('team_slug');
      params.team_token = queryparameter.get('team_token');
      setInvitedUserParams(params);
      setInvitedUser(true);
    }
  }
  const setShowModel = () => {
    setInvitedUser(false)
  }
  const closeModel = () => { setIsShowModal(false) }
  const openModel = () => { setIsShowModal(true) }

  const getTournamentList = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const url = `${BASE_URL}${TOURNAMENT_LIST}?tournament_state=${tournamentState}`;
      const response = await axios.get(url, {
        headers: headers,
      });

      if (response.data.status_code === 200 && response.data.status === true) {
        setIsLoading(false);
        const tournamentList = response.data.tournaments
        const selectedTournaments = tournamentList.slice(0, 3);
        setTournamentList(selectedTournaments);

        //   toast.success(response.data.message);
      } else if (
        response.data.status_code === 400 &&
        response.data.status === false
      ) {
        toast.error(response?.data?.error[0]);
        setIsLoading(false);
      } else if (
        response.data.status_code === 403 &&
        response.data.status === false
      ) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        // console.log('response.data::',response)
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message || "An error occurred";
        setIsLoading(false);
        toast.error(errorMessage);
      } else {
        toast.error("An error occurred");
        setIsLoading(false);
      }
    }
  };

  const setSelectedTournament = (id) => {
    setSelectedTournamentId(id)
    openModel();
  }


  return (
    <>
      {invitedUser && (
        <UserInviteModel invitedUser={invitedUser} setShowModel={setShowModel} inviteParams={invitedUserParams} />
      )}
      {isShowModal && <EnrollNowModal isShowModal={isShowModal} closeModel={closeModel} tournament_id={selectedTournamentId} />}
      <Header />
      <section className='banner-home-sec' id="HOME">
        <Container>
          <div className="herosec">
            <p className='subtitle-hero'>Made For The Players, By The Players</p>
            {/* <p className='subtitle-hero'># World Class ESports & Gaming Site</p> */}
            <h1>
              <span className='span-heromain'>
                Eu Pro Scene
              </span>
              <br />
              {/* <span>
                TOURNAMENT
              </span> */}
            </h1>
            <div className="col-auto d-xl-block">
              <div className="header-button">
                <div className="d-xxl-block "><Link to="/tournament" className="th-btn th_btn">VIEW TOURNAMENTS <i
                  className="fa-solid fa-arrow-right ms-2"></i> </Link></div>
              </div>
            </div>
          </div>
        </Container>
      </section>

      {/* second-sec --------------------------------------- */}
      <section className='sec-doumble'>
        <Container>
          <div className="cta-area-1">
            <div className="cta-bg-shape-border">
              <svg viewBox="0 0 1464 564" fill="none">
                <path
                  d="M1463.5 30V534C1463.5 550.292 1450.29 563.5 1434 563.5H1098H927.426C919.603 563.5 912.099 560.392 906.567 554.86L884.14 532.433C878.42 526.713 870.663 523.5 862.574 523.5H601.426C593.337 523.5 585.58 526.713 579.86 532.433L557.433 554.86C551.901 560.392 544.397 563.5 536.574 563.5H366H30C13.7076 563.5 0.5 550.292 0.5 534V30C0.5 13.7076 13.7076 0.5 30 0.5H366H536.574C544.397 0.5 551.901 3.60802 557.433 9.14034L579.86 31.5668C585.58 37.2866 593.337 40.5 601.426 40.5H862.574C870.663 40.5 878.42 37.2866 884.14 31.5668L906.567 9.14035C912.099 3.60803 919.603 0.5 927.426 0.5H1098H1434C1450.29 0.5 1463.5 13.7076 1463.5 30Z"
                  stroke="url(#paint0_linear_202_547)"></path>
                <defs>
                  <linearGradient id="paint0_linear_202_547" x1="0" y1="0" x2="1505.47" y2="412.762"
                    gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="var(--theme-color)"></stop>
                    <stop offset="1" stopColor="var(--theme-color2)"></stop>
                  </linearGradient>
                </defs>
              </svg></div>
            <div className="cta-wrap-bg bg-repeat mask-img background-image bg-mask"
            >
              <div className="cta-thumb">
                {/* <img src={peopleimg} alt="" /> */}
              </div>
            </div>
            <div className="cta-wrap">
              <div className="row">
                <div className="col-xl-12">
                  <div className="title-area mb-0 custom-anim-left wow"
                  >
                    {/* <h2 className="title-amin-by">BUY CREDITS & ENJOY
                      TOURNAMENT EVERYDAY!</h2> */}
                    <h2 className="title-amin-by">BUY CREDITS & ENJOY TOURNAMENTS</h2>
                    {/* <p className="text-damble-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor massa ut metus
                      dictum facilisis. Nunc eget erat ac nunc condimentum tempus vitae et dui.</p> */}
                    <div className="d-xxl-block"><a className="th-btn th_btn" onClick={() => navigate('/myprofile', { state: { menu: 4 } })}>Connect Wallet <i className="fa-solid fa-arrow-right ms-2"></i></a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>

      {/* sec third ---------------------------------- */}

      <section className='comn-padd' id="ABOUT-US">
        <Container>
          <div className="inner-sec-third">
            <Row>
              <Col lg={5} md={12}>
                <div className="inner-img-sec-third">
                  <figure>
                    <img className='img-fluid' src={secthird} alt="" />
                  </figure>

                </div>
              </Col>
              <Col lg={1} md={0}></Col>
              <Col lg={6} md={12}>
                <div className="inner-tetx-sec-third">
                  {/* <h2 className='text-uppercase'>Forging Legends In The
                    Gaming Universe</h2> */}
                  <h2 className='text-uppercase'>Home of the EU Pro Scene</h2>
                  <p className='text-sec-third'>EuProScene [EPS] is your primary source for the most competitive and action-packed tournaments.
                    EPS will strive to provide a comprehensive platform for the EU gaming community, offering a diverse range of competitive gaming experiences. These will include 1v1, 2v2, 3v3, and 4v4 tournaments on a weekly basis.
                    We will be hosting weekly tournaments, followed by a major monthly Elite VIP only tournament.
                    Our objective is to establish a trusted EU gaming platform where players can engage in meaningful feedback and communication to help improve the platform and EU scene which is something clearly lacking for many years.</p>
                </div>
                <div className="list-sec-third">
                  <div className="inner-flex-sec-third d-flex">
                    <div className="upper-hexa-img">
                      <img src={hexa} alt="" />
                      <div className="inner-list-img">
                        <img src={gorup2} alt="" />
                      </div>
                    </div>
                    <div className="text-righ-sec-third">
                      <h3>We can <span> host </span>your tournaments</h3>
                      <p>Should you wish for us to organize any particular tournaments for you, we are at your disposal to include any game you require. We are also available to discuss the specifics in greater detail via Discord.</p>
                    </div>
                  </div>
                  <div className="inner-flex-sec-third d-flex">
                    <div className="upper-hexa-img">
                      <img src={hexa} alt="" />
                      <div className="inner-list-img">
                        <img src={log} alt="" />
                      </div>
                    </div>
                    <div className="text-righ-sec-third">
                      <h3>Great Tournaments</h3>
                      <p>We're setting up over 220 tournaments in the upcoming year, each with prize amounts exceeding £2000 per month. As the community expands, the size of the prize pools increase.</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

          </div>
        </Container>

      </section>


      {/* how it work --------------------------------- */}

      <section className='comn-padd how-it-work' id="HOW-IT-WORKS">
        <Container>
          <div className="inner-sec-how-it-work">
            <h2 className='text-uppercase'>How It Works</h2>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor massa ut metus dictum facilisis. Nunc eget erat ac nunc condimentum tempus vitae et dui. Vestibulum sem lorem, cursus id interdum et, cursus in lorem. Aliquam hendrerit lacinia mauris, a interdum orci aliquet nec.</p> */}
          </div>
          <div className="clip-sec-right">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="inner-clip-red-sec">
                  <figure>
                    <img src={head} alt="" />
                  </figure>
                  <h2>sign up</h2>
                  <p>Register and compete in weekly tournaments</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="inner-clip-red-sec">
                  <figure>
                    <img src={sec} alt="" />
                  </figure>
                  <h2>Compete</h2>
                  <p>Play some of the best EU players on the scene and show your talent</p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="inner-clip-red-sec">
                  <figure>
                    <img src={log} alt="" />
                  </figure>
                  <h2>win and earn  </h2>
                  <p>Compete in tournaments with prize pools of over £24,000 a year</p>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>


      <section className='comn-padd' id='FEATURED-COMPETITIONS'>
        <Container>
          <div className="upper-header-main-lock d-flex">
            <h3 className='text-uppercase'>Featured Competitions</h3>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor massa ut metus dictum facilisis. Nunc eget erat ac nunc condimentum tempus vitae et dui. Vestibulum sem lorem, cursus id interdum et, cursus in lorem. Aliquam hendrerit lacinia mauris</p> */}

          </div>

          {tournamentList.map((element, key) => {
            return <div key={key} className="upper-lock-main">
              <div className="inner-red-main">
                <div className="inner-red-wrapper">
                  <Row>
                    <Col lg="6">
                      <div className="teams-img d-flex">
                        <figure className="card-img">
                          <img src={element.tournament_banner
                            ? IMAGE_BASE_URL + element.tournament_banner
                            : logo} alt="" />
                        </figure>
                      </div>
                    </Col>
                    <Col lg="6">
                      <div className="inner-text-main-lock">
                        <div className="wrapp-text d-flex">
                          <h2>{element.tournament_name}</h2>
                          <div className="d-xxl-block "><a className="th-btn th_btn" onClick={() => setSelectedTournament(element.id)}>ENROLL NOW<i className="fa-solid fa-arrow-right ms-2"></i></a></div>
                        </div>
                        <p className='date-red'>{element.registration_deadline_date}</p>
                        <p className='text-main-red'>{element.description}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
              {
                !user ?
                  <div className="lock-wrapper">
                    <div className="inner-wrap-ll">
                      <div className="inner-h">
                        <div className="inm-ock text-center">
                          <img src={lock} alt="" />
                        </div>
                        <h3>To Unlock This Tournament You
                          Have To Register</h3>
                        <div className="d-xxl-block "><a className="th-btn th_btn"  onClick={() => navigate('/signup')} >REGISTER NOW<i className="fa-solid fa-arrow-right ms-2"></i></a></div>
                      </div>
                    </div>
                  </div>
                  :
                  <></>
              }
            </div>
          })}
          {/* <div className="upper-lock-main">
            <div className="inner-red-main">
              <div className="inner-red-wrapper">
                <Row>
                  <Col lg="6">
                    <div className="teams-img d-flex">
                      <figure className='team-main1'>
                        <img src={tema1} alt="" />
                      </figure>
                      <figure className='vsclass'>
                        <img src={vs} alt="" />
                      </figure>
                      <figure className='team-main1'>
                        <img src={team2} alt="" />
                      </figure>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="inner-text-main-lock">
                      <div className="wrapp-text d-flex">
                        <h2>Lorem Ipsum Sit Dolor</h2>
                        <div className="d-xxl-block "><a className="th-btn th_btn">ENROLL NOW<i className="fa-solid fa-arrow-right ms-2"></i></a></div>
                      </div>
                      <p className='date-red'>20 Jun, 2024</p>
                      <p className='text-main-red'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor massa ut metus dictum facilisis. Nunc eget erat ac nunc condimentum tempus vitae et dui. Vestibulum sem lorem, cursus id interdum et, cursus in lorem. Aliquam hendrerit lacinia mauris</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            {
              !user ?
              <div className="lock-wrapper">
              <div className="inner-wrap-ll">
                <div className="inner-h">
                  <div className="inm-ock text-center">
                    <img src={lock} alt="" />
                  </div>
                  <h3>To Unlock This Tournament You
                    Have To Register</h3>
                  <div className="d-xxl-block "><a className="th-btn th_btn" href='/signup'>REGISTER NOW<i className="fa-solid fa-arrow-right ms-2"></i></a></div>
                </div>
              </div>
            </div>
            :
            <></>
            }
          </div> */}


          {/* <div className="upper-lock-main">
            <div className="inner-red-main">
              <div className="inner-red-wrapper">
                <Row>
                  <Col lg="6">
                    <div className="teams-img d-flex">
                      <figure className='team-main1'>
                        <img src={tema1} alt="" />
                      </figure>
                      <figure className='vsclass'>
                        <img src={vs} alt="" />
                      </figure>
                      <figure className='team-main1'>
                        <img src={team2} alt="" />
                      </figure>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="inner-text-main-lock">
                      <div className="wrapp-text d-flex">
                        <h2>Lorem Ipsum Sit Dolor</h2>
                        <div className="d-xxl-block "><a className="th-btn th_btn">ENROLL NOW<i className="fa-solid fa-arrow-right ms-2"></i></a></div>
                      </div>
                      <p className='date-red'>20 Jun, 2024</p>
                      <p className='text-mafield
                </Row>
              </div>
            </div>
            {
              !user ?
              <div className="lock-wrapper">
              <div className="inner-wrap-ll">
                <div className="inner-h">
                  <div className="inm-ock text-center">
                    <img src={lock} alt="" />
                  </div>
                  <h3>To Unlock This Tournament You
                    Have To Register</h3>
                  <div className="d-xxl-block "><a className="th-btn th_btn" href='/signup'>REGISTER NOW<i className="fa-solid fa-arrow-right ms-2"></i></a></div>
                </div>field
              </div>
            </div>
            :
            <></>
            }

          </div> */}
        </Container>
      </section>
      {/* contact us -------------------- */}
      <section className='contatc-us comn-padd' id="CONTACT-US">
        <Container>
          <div className="inner-sec-how-it-work">
            <h2>Contact Us</h2>
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor massa ut metus dictum facilisis. Nunc eget erat ac nunc condimentum tempus vitae et dui. Vestibulum sem lorem, cursus id interdum et, cursus in lorem. Aliquam hendrerit lacinia mauris, a interdum orci aliquet nec.</p> */}
          </div>

          <Row>
            <div className="col-lg-2"></div>
            <Col lg={4}>
              <div className="upper-padd">
                <div className="inner-shaddow">
                  <a href="https://www.instagram.com/euproscene/" target="_blank" rel="noopener noreferrer"><i class="fab fa-instagram"></i></a>
                  <p>Our Instagram Handle
                  </p>

                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="upper-padd">
                <div className="inner-shaddow">
                  <a href="https://x.com/EUProScene" target="_blank" rel="noopener noreferrer"><i class="fab fa-twitter"></i></a>
                  <p>Our Twitter Handle
                  </p>
                </div>
              </div>
            </Col>
            <div className="col-lg-2"></div>
            </Row>

          {/* <Row>
            <Col lg={4}>
              <div className="upper-padd">
                <div className="inner-shaddow">
                  <i class="fa-solid fa-location-dot"></i>
                  <p>Suite 603-21183 88 Ave ,Langley <br />V1M 2G5, Canada
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="upper-padd">
                <div className="inner-shaddow">
                  <i class="fa-solid fa-phone"></i>
                  <div className="numbers">
                    <a href="tel:+7676767642">+76767676423</a> <br />
                    <a href="tel:+7676767642">+23323523523</a>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="upper-padd">
                <div className="inner-shaddow">
                  <i class="fa-solid fa-envelope"></i>
                  <div className="numbers">
                    <a href="mailto:math@gmail.com">math@gmail.com</a> <br />
                    <a href="mailto:infomath@gmail.com">infomath@gmail.com</a>
                  </div>
                </div>
              </div>
            </Col>
          </Row> */}
        </Container>
      </section>

      <section className='profesniol comn-padd how-it-work'>
        <Container>
          <div className="inner-rpofesional">
            <h1 className="span-heromain"><span >Eu Pro Scene</span> <br /> 
            {/* <span className='tournament-main'>TOURNAMENT</span> */}
            </h1>

            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor massa ut metus dictum facilisis. Nunc eget erat ac nunc condimentum tempus vitae et dui. Vestibulum sem lorem, cursus id interdum et, cursus in lorem. Aliquam hendrerit lacinia mauris, a interdum orci aliquet nec.</p> */}
            {
              !user ?
                <div className="header-button d-flex"><a className="login-head" onClick={() => navigate('/login')}>Login</a><div className="d-xxl-block"><a className="th-btn th_btn" onClick={() => navigate('/signup')}>REGISTER NOW</a></div></div>
                :
                <></>
            }
          </div>
        </Container>
      </section>



      <Footer />
    </>
  )
}

export default Homepage

